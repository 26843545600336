import { useCallback, useEffect, useState } from 'react'

import { DeleteOutlined, WarningFilled } from '@ant-design/icons'
import { baseColors } from '@dialogue/basics'
import { DocumentFormat, type WriteAccess } from '@dialogue/document-center'
import { Button, Popover, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  useCanDeleteDocument,
  useCanEditDocument,
} from 'app/containers/documents/hooks/access-control'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { memberDocumentsActions } from 'app/redux/documents/members'
import {
  selectDocumentBytesError,
  selectDocumentBytesLoading,
} from 'app/redux/documents/selectors'

import AutofillButton from './autofill/popover-button'
import { MODAL_TYPE, useViewerInstance } from './viewer-context'

const FooterWrapper = styled.div<{ height: number }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1rem;
  background-color: ${baseColors.smokeLight};
  border-top: 1px solid ${baseColors.smoke};
  height: ${(props) => (props.height ? `${props.height}px` : 'initial')};
`

interface FooterProps {
  height: number
  memberId: number
  documentId?: string
  documentFormat?: DocumentFormat
  documentName?: string
  onClose?: () => void
  writeAccess?: WriteAccess
  creatorId?: number
}

export const Footer = ({
  height,
  memberId,
  documentId,
  documentFormat,
  documentName,
  onClose,
  writeAccess,
  creatorId,
}: FooterProps) => {
  const { t } = useTranslation()
  const { t: tCommon } = useTranslation('common')
  const dispatch = useAppDispatch()
  // saved is used to represent when the API call has been made to save changes
  // (whether they were successful or not doesn't matter).
  const [saved, setSaved] = useState<boolean>(false)
  // isSaving is used exclusively used to show loading spinner in the save button.
  const [isSaving, setIsSaving] = useState<boolean>(false)
  // updatingDocumentBytes is used to represent the API actively working on saving the document.
  const updatingDocumentBytes = useAppSelector((state) =>
    selectDocumentBytesLoading(state, memberId),
  )
  const errorSavingDocumentBytes = useAppSelector((state) =>
    selectDocumentBytesError(state, memberId),
  )

  const { isDeletable, isDeletableTooltip } = useCanDeleteDocument(
    writeAccess,
    creatorId,
  )
  const { isEditable, isEditableTooltip } = useCanEditDocument(
    writeAccess,
    creatorId,
  )

  const {
    isViewerEditable,
    loadedDocumentId,
    documentHasSignature,
    enableViewerEdit,
    disableViewerEdit,
    setModal,
    getFileData,
    areRequiredFieldsFilled,
  } = useViewerInstance()

  const error = !!errorSavingDocumentBytes
  const inEditMode = isViewerEditable || isSaving
  // allowUpdate is used to represent when a document has been loaded into the viewer
  // and is ready to honour edit modes.
  const allowUpdate = documentId && loadedDocumentId
  // Editing features are only allowed for PDF documents,
  // as Apryse encodes any file with annotations as a PDF
  const allowEditing = allowUpdate && documentFormat === DocumentFormat.doc

  const saveFile = useCallback(async () => {
    try {
      const fileBlob = await getFileData()

      if (!documentId || !documentName || !fileBlob) {
        let initialMessage =
          "Missing required arguments to save document, won't send. Missing argument(s): "

        const missingArguments: (string | boolean)[] = [
          !documentId && 'documentId',
          !documentName && 'documentName',
          !fileBlob && 'fileBlob',
        ]

        initialMessage += missingArguments
          .filter((arg) => arg !== false)
          .join(', ')

        console.warn(initialMessage)
        throw new Error(initialMessage)
      }

      dispatch(
        memberDocumentsActions.updateDocumentBytes({
          memberId,
          documentId,
          document: new File([fileBlob], documentName),
        }),
      )
      setSaved(true)
    } catch (error) {
      dispatch(
        memberDocumentsActions.errorUpdatingDocumentBytes({
          memberId,
          error,
        }),
      )
      console.error(
        'Attempting to generate file from viewer failed with error: ',
        error,
      )
    }
  }, [memberId, documentId, documentName, getFileData, dispatch])

  useEffect(() => {
    // 1. We've gotten an error, loading no longer happening
    if (isSaving && error) {
      enableViewerEdit()
      setIsSaving(false)
    }

    // 2. API has successfully saved, loading no longer happening
    if (!updatingDocumentBytes && saved) {
      setSaved(false)
      setIsSaving(false)
    }
  }, [error, enableViewerEdit, isSaving, saved, updatingDocumentBytes])

  const onDelete = useCallback(() => {
    const onConfirm = () => {
      disableViewerEdit()
      onClose?.()
    }

    setModal?.({ type: MODAL_TYPE.CONFIRM_DELETE, onConfirm })
  }, [setModal, disableViewerEdit, onClose])

  const handleReviewClick = useCallback(() => {
    setModal?.({ type: MODAL_TYPE.CONFIRM_REVIEW })
  }, [setModal])

  const handleEditClick = useCallback(() => {
    if (documentHasSignature) {
      setModal?.({ type: MODAL_TYPE.CONFIRM_EDIT })
    } else {
      enableViewerEdit()
    }
  }, [documentHasSignature, enableViewerEdit, setModal])

  const handleSaveClick = useCallback(() => {
    setIsSaving(true)

    // If required fields are not filled, we should not proceed with saving
    if (!areRequiredFieldsFilled()) {
      setIsSaving(false)
      return
    }
    disableViewerEdit(true) // while saving they should not be allowed to make edits
    saveFile()
  }, [disableViewerEdit, areRequiredFieldsFilled, saveFile])

  const handleCancel = useCallback(() => {
    dispatch(
      memberDocumentsActions.resetUpdatingDocumentBytes({
        memberId,
      }),
    )
    disableViewerEdit()
  }, [memberId, disableViewerEdit, dispatch])

  return (
    <FooterWrapper height={height}>
      <div>
        {allowEditing && inEditMode && (
          <AutofillButton memberId={memberId} documentId={documentId} />
        )}
        {!inEditMode && (
          <Tooltip title={isDeletableTooltip}>
            <Button
              type="link"
              size="small"
              danger
              icon={<DeleteOutlined />}
              onClick={onDelete}
              data-testid="delete-document"
              disabled={!isDeletable}
            >
              {tCommon('button.delete')}
            </Button>
          </Tooltip>
        )}
      </div>
      <div>
        <>
          {allowUpdate && !inEditMode && (
            <Button
              ghost
              style={{ marginRight: '2rem' }}
              onClick={handleReviewClick}
              data-testid="review-document"
            >
              {t('documents.actions.review')}
            </Button>
          )}
          {allowEditing && !inEditMode && (
            <Tooltip title={isEditableTooltip}>
              <Button
                type="primary"
                onClick={handleEditClick}
                disabled={!isEditable}
                data-testid={'edit-document'}
              >
                {tCommon('button.edit')}
              </Button>
            </Tooltip>
          )}
        </>
        {allowEditing && inEditMode && (
          <>
            <Button
              type="text"
              style={{ marginRight: '2rem' }}
              onClick={handleCancel}
            >
              {tCommon('button.cancel')}
            </Button>
            <Button type="primary" loading={isSaving} onClick={handleSaveClick}>
              {tCommon('button.saveChanges')}
            </Button>
          </>
        )}
        {error && (
          <Popover
            trigger="hover"
            content={t('documents.modalCopy.confirmSave.error')}
          >
            <WarningFilled
              style={{ color: baseColors.massacre, marginLeft: '0.75rem' }}
              data-testid="error"
            />
          </Popover>
        )}
      </div>
    </FooterWrapper>
  )
}
